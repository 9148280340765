import React, { Component } from "react";
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

const BlogDetailsPageHelmet = ({ title, description, image, keywords }) => {
    return (
        <React.Fragment>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
                <meta name="image" content={image} />
                <meta property="og:url" content={`https://dushandev.com/blog/${title}`} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={image} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content="@dushandev" />
                <meta name="twitter:site" content="@dushandev" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={image} />
                <meta name="twitter:alt" content={title} />
                <link rel="canonical" href={`https://dushandev.com/blog/${title}`} />
            </Helmet>
        </React.Fragment>
    )
}

BlogDetailsPageHelmet.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.string,
    image: PropTypes.string,
}

export default BlogDetailsPageHelmet;
