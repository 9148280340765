import React from 'react'
import PropTypes from 'prop-types'

const BlogDetailsPlaceholderImage = ({ marginTop = 50}) => {
    return (
        <div  style={{ marginTop: marginTop ? `${marginTop}px` : '10px', height: `calc(100vh -${marginTop}px)` }}>
            <div className='row'>
                <div className={`col-12`} >
                    <div
                        className="placeholder fade-in-left-right"
                        style={{
                            backgroundColor: '#d4d4d4',
                            margin: '10px 20px 10px 20px',
                            borderRadius: '5px',
                            height: `400px`
                        }}
                    >
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className={`col-12`} >
                    <div
                        className="placeholder fade-in-left-right"
                        style={{
                            backgroundColor: '#d4d4d4',
                            margin: '10px 100px 0px 100px',
                            borderRadius: '5px',
                            height: `50px`
                        }}
                    >
                    </div>
                </div>
                <div className={`col-12`} >
                    <div
                        className="placeholder fade-in-left-right"
                        style={{
                            backgroundColor: '#d4d4d4',
                            margin: '10px 100px 0px 100px',
                            borderRadius: '5px',
                            height: `40px`
                        }}
                    >
                    </div>
                </div>
                <div className={`col-12`} >
                    <div
                        className="placeholder fade-in-left-right"
                        style={{
                            backgroundColor: '#d4d4d4',
                            margin: '10px 100px 0px 100px',
                            borderRadius: '5px',
                            height: `40px`
                        }}
                    >
                    </div>
                </div>
                <div className={`col-12`} >
                    <div
                        className="placeholder fade-in-left-right"
                        style={{
                            backgroundColor: '#d4d4d4',
                            margin: '10px 100px 0px 100px',
                            borderRadius: '5px',
                            height: `40px`
                        }}
                    >
                    </div>
                </div>
                <div className={`col-8`} >
                    <div
                        className="placeholder fade-in-left-right"
                        style={{
                            backgroundColor: '#d4d4d4',
                            margin: '10px 0px 0px 100px',
                            borderRadius: '5px',
                            height: `100px`
                        }}
                    >
                    </div>
                </div>
                <div className={`col-4`} >
                    <div
                        className="placeholder fade-in-left-right"
                        style={{
                            backgroundColor: '#d4d4d4',
                            margin: '10px 100px 0px 0px',
                            borderRadius: '5px',
                            height: `100px`
                        }}
                    >
                    </div>
                </div>
            </div>
        </div>
    )
}

BlogDetailsPlaceholderImage.propTypes = {
    marginTop: PropTypes.number
}

export default BlogDetailsPlaceholderImage