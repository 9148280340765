import React from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";

import MainHeader from "../component/header/HeaderBlog";
import SecondaryHeader from "../component/header/HeaderBlogDetails";

const BlogLayout = () => {
  return (
    <>
      <MainHeader
        headertransparent="header--transparent"
        colorblack="color--black"
        logoname="logo.png"
      />
      <Outlet />
    </>
  );
};
export default BlogLayout;
