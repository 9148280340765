import React from 'react'
import PropTypes from 'prop-types'
import BlogCardPlaceholderImage from './BlogCardPlaceholderImage'

const BlogListBlockPlaceholderImage = ({ height = 400 }) => {
    return (
        <div className='container' style={{ marginTop:'0px' }}>
            <div className='row'>

                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                    <div
                        className="placeholder fade-in-left-right"
                        style={{
                            margin: '10px',
                            borderRadius: '5px',
                            height: `${height}px`,
                            width: '100%'
                        }}
                    >
                        <BlogCardPlaceholderImage />
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                    <div
                        className="placeholder fade-in-left-right"
                        style={{
                            margin: '10px',
                            borderRadius: '5px',
                            height: `${height}px`,
                            width: '100%'
                        }}
                    >
                        <BlogCardPlaceholderImage />
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                    <div
                        className="placeholder fade-in-left-right"
                        style={{
                            margin: '10px',
                            borderRadius: '5px',
                            height: `${height}px`,
                            width: '100%'
                        }}
                    >
                        <BlogCardPlaceholderImage />
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                    <div
                        className="placeholder fade-in-left-right"
                        style={{
                            margin: '10px',
                            borderRadius: '5px',
                            height: `${height}px`,
                            width: '100%'
                        }}
                    >
                        <BlogCardPlaceholderImage />
                    </div>
                </div>

            </div>
        </div>
    )
}

BlogListBlockPlaceholderImage.propTypes = {
    height: PropTypes.number
}

export default BlogListBlockPlaceholderImage