import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn, FaGithub, FaStackOverflow, FaGooglePlay } from "react-icons/fa";
import { FiX, FiMenu } from "react-icons/fi";
import Scrollspy from 'react-scrollspy'

import { withRouter } from "../../hooks/withRouter";

const SocialShare = [
    { Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/in/dushan-ranasinghage' },
    { Social: <FaGithub />, link: 'https://github.com/dushan-ranasinghage' },
    { Social: <FaStackOverflow />, link: 'https://stackoverflow.com/users/10263108/dushan-ranasinghage' },
    { Social: <FaGooglePlay />, link: 'https://play.google.com/store/apps/developer?id=dExTeR' },
    { Social: <FaTwitter />, link: 'https://twitter.com/dushandev' },
]

class HeaderBlog extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function () {
            console.log('All assets are loaded');
        })

    }

    state = {
        pathname: ''
    }

    componentDidMount() {
        this.setState({ pathname: this.props.history.pathname })
    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }

    stickyHeader() { }

    render() {

        window.addEventListener('scroll', function () {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed') && document.querySelector('.header--fixed').classList.add('sticky')
            } else {
                document.querySelector('.header--fixed') && document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        const { logo, color = 'default-color' } = this.props;
        let logoUrl;
        if (logo === 'light') {
            logoUrl = <img src="/assets/images/logo/logo-symbol-dark-dushan.png" alt="Digital Agency" />;
        } else if (logo === 'dark') {
            logoUrl = <img src="/assets/images/logo/logo-symbol-dark-dushan.png" alt="Digital Agency" />;
        } else if (logo === 'symbol-dark') {
            logoUrl = <img src="/assets/images/logo/logo-symbol-dark-dushan.png" alt="Digital Agency" />;
        } else if (logo === 'symbol-light') {
            logoUrl = <img src="/assets/images/logo/logo-symbol-dark-dushan.png" alt="Digital Agency" />;
        } else {
            logoUrl = <img src="/assets/images/logo/logo-symbol-dark-dushan.png" alt="Digital Agency" />;
        }
        console.log("PAAA", this.state.pathname === "/blog")
        return (
            <header className={`header-area header-style-two header--fixed ${color}`}>
                <div className="header-wrapper">
                    <div className="header-left d-flex align-items-center">
                        <div className="logo">
                            {/* <Link to={`/blog`}>
                                {logoUrl}
                            </Link> */}
                        </div>
                        <nav className="mainmenunav d-lg-block ml--50">
                            <ul className="mainmenu">
                                {/* <li><a href="#home">Home</a></li>
                                <li><a href="#about">About</a></li>
                                <li><a href="#skills">Skills</a></li>
                                <li><a href="#portfolio">Portfolio</a></li>
                                <li><a href="#contact">Contact</a></li> */}
                                <li className={this.state.pathname === "/" ? "is-current" : ""}><Link to="/">Home</Link></li>
                                <li className={this.state.pathname === "/blog" ? "is-current" : ""}><Link to="/blog" className={this.state.pathname === "/blog" ? "is-current" : ""}>Blog</Link></li>
                            </ul>
                        </nav>
                    </div>
                    <div className="header-right">
                        <nav className="mainmenunav d-lg-block ml--50">
                            {/* <Scrollspy className="mainmenu" items={['react', 'javascript', 'back-end', 'other']} currentClassName="is-current" offset={-200}>
                                <li><a href="#react">React</a></li>
                                <li><a href="#javascript">JavaScript</a></li>
                                <li><a href="#back-end">Back-End</a></li>
                                <li><a href="#other">Other</a></li>
                            </Scrollspy> */}
                        </nav>
                        <div className="header-btn">
                            {/* <a className="rn-btn" href="https://themeforest.net/checkout/from_item/25457315?license=regular">
                                <span>buy now</span>
                            </a> */}
                        </div>
                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default withRouter(HeaderBlog);