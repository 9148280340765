import React from "react";
import PropTypes from "prop-types";

const BlogCardPlaceholderImage = ({ showWordLines = true, }) => {
  const showLines = () => {
    return (
      <>
        <div
          className="placeholder fade-in-left-right"
          style={{
            backgroundColor: "#d4d4d4",
            margin: "10px",
            borderRadius: "5px",
            height: "2%",
            width: "100%",
          }}
        ></div>
        <div
          className="placeholder fade-in-left-right"
          style={{
            backgroundColor: "#d4d4d4",
            margin: "10px",
            borderRadius: "5px",
            height: "2%",
            width: "100%",
          }}
        ></div>
        <div
          className="placeholder fade-in-left-right"
          style={{
            backgroundColor: "#d4d4d4",
            margin: "10px",
            borderRadius: "5px",
            height: "2%",
            width: "80%",
          }}
        ></div>
        <div
          className="placeholder fade-in-left-right"
          style={{
            backgroundColor: "#d4d4d4",
            margin: "10px",
            borderRadius: "5px",
            height: "2%",
            width: "80%",
          }}
        ></div>
        <div
          className="placeholder fade-in-left-right"
          style={{
            backgroundColor: "#d4d4d4",
            margin: "10px",
            borderRadius: "5px",
            height: "2%",
            width: "80%",
          }}
        ></div>
        <div
          className="placeholder fade-in-left-right"
          style={{
            backgroundColor: "#d4d4d4",
            margin: "10px",
            borderRadius: "5px",
            height: "2%",
            width: "80%",
          }}
        ></div>
      </>
    );
  };
  return (
    <div className="container h-100">
      <div className="row h-100">
        <div className="col-12 h-100">
          <div
            className="placeholder fade-in-left-right"
            style={{
              backgroundColor: "#d4d4d4",
              margin: "10px",
              borderRadius: "5px",
              height: "100%",
              width: "100%",
            }}
          ></div>
          {showWordLines && showLines()}
        </div>
      </div>
    </div>
  );
};

BlogCardPlaceholderImage.propTypes = {
  showWordLines: PropTypes.bool
};

export default BlogCardPlaceholderImage;
