import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>Dushan Dev | Dushan Ranasinghage Dev Story</title>
                    <meta name="keywords" content="dushan ranasinghage, javascript deveopler, front-end, sri lanka tech, dushan, dushan portfolio, tech, technology" />
                    <meta itemProp="title" content={`Dushan Dev | Dushan Ranasinghage Dev Story`} />
                    <meta itemProp="url" content="https://dushandev.com/" />
                    <meta name="description" content="Experienced Front-end Developer specializing in React, JavaScript/ TypeScript and Next.js. I help tech industry to build top-notch web development solutions with an emphasis on user experience and SEO optimization."/>
                    <meta property="og:title" content="Dushan Ranasinghage"/>
                    <meta property="og:description" content="Experienced Front-end Developer specializing in React, JavaScript/ TypeScript and Next.js. I help tech industry to build top-notch web development solutions with an emphasis on user experience and SEO optimization."/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:image" content="https://www.dushandev.com/assets/images/about/og-default.png"/>
                    <meta property="og:image:alt" content="Banner for dushandev.com, featuring a photo, profession, and location."/>
                    <meta property="og:image:width" content="1280"/>
                    <meta property="og:image:height" content="675"/>
                    <meta name="twitter:card" content="summary_large_image"/>
                    <meta name="twitter:creator" content="@dushandev"/>
                    <meta name="twitter:title" content="Dushan Ranasinghage"/>
                    <meta name="twitter:description" content="Experienced Front-end Developer specializing in React, JavaScript/ TypeScript and Next.js. I help tech industry to build top-notch web development solutions with an emphasis on user experience and SEO optimization."/>
                    <meta name="twitter:image" content="https://www.dushandev.com/assets/images/about/og-default.png"/>
                    <link rel="canonical" href="https://www.dushandev.com"/>
                    <meta name="next-head-count" content="20"/>
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
