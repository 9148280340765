import localforage from "localforage";

var db = localforage.createInstance({
  driver      : localforage.INDEXEDDB, // Force WebSQL; same as using setDriver()
  name        : 'dushan-db',
  version     : 1.0,
  size        : 4980736, // Size of database, in bytes. WebSQL-only for now.
  storeName   : 'portfolio-db', // Should be alphanumeric, with underscores.
  description : 'Cache db for the portfolio web.'
});

export default db