import { createSlice, current } from '@reduxjs/toolkit'

import { getBlogs, getFeaturedBlogs, getBlog, getBlogByTitle } from '../actions/blogs'

// status: 'idle' | 'pending' | 'succeeded' | 'failed'
const INITIAL_STATE = {
  items: [],
  status: 'idle',
  featuredItems: [],
  featuredStatus: 'idle',
  item: null,
  blogStatus: 'idle',
}

export const counterSlice = createSlice({
  name: 'blogs',
  initialState: INITIAL_STATE,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getBlogs.pending, (state, action) => {
      state.status = 'pending'
    });
    builder.addCase(getBlogs.fulfilled, (state, action) => {
      state.items = action.payload
      state.status = 'succeeded'
    });
    builder.addCase(getBlogs.rejected, (state, action) => {
      state.status = 'failed'
    });
    builder.addCase(getFeaturedBlogs.pending, (state, action) => {
      state.featuredStatus = 'pending'
    });
    builder.addCase(getFeaturedBlogs.fulfilled, (state, action) => {
      state.featuredItems = action.payload
      state.featuredStatus = 'succeeded'
    });
    builder.addCase(getFeaturedBlogs.rejected, (state, action) => {
      state.featuredStatus = 'failed'
    });
    // builder.addCase(getBlog.pending, (state, action) => {
    //   state.blogStatus = 'pending'
    // });
    // builder.addCase(getBlog.fulfilled, (state, action) => {
    //   state.item = action.payload
    //   state.blogStatus = 'succeeded'
    // });
    // builder.addCase(getBlog.rejected, (state, action) => {
    //   state.blogStatus = 'failed'
    // });
    builder.addCase(getBlogByTitle.pending, (state, action) => {
      state.blogStatus = 'pending'
    });
    builder.addCase(getBlogByTitle.fulfilled, (state, action) => {
      state.item = action.payload
      state.blogStatus = 'succeeded'
    });
    builder.addCase(getBlogByTitle.rejected, (state, action) => {
      state.blogStatus = 'failed'
    });
  },
})

export default counterSlice.reducer