import React, { useEffect, useState, Fragment } from "react";
import { connect, useDispatch } from "react-redux";

import PageHelmet from "../component/common/Helmet/Blog";
import Breadcrumb from "./common/BreadcrumbCommon";
import Pagination from "../elements/common/Pagination";
import BlogList from "../elements/blog/BlogList";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
// import Header from "../component/header/Header";
import Header from "../component/header/HeaderBlog";
import Footer from "../component/footer/FooterTwo";
import HeroBlogs from "./blog/components/HeroBlogs";
import PreviousBlogs from "./blog/components/PreviousBlogs";
import UnderConstruction from "../component/common/UnderConstruction";

import { getBlogs, getFeaturedBlogs } from "../redux/actions/blogs";
import store from "../redux/store";
import {
  getBlogsSelector,
  getBlogsStatusSelector,
} from "../redux/selectors/blogs";
import cacheDb from "../cache/db";
import { BLOGS_ITEMS_LIMIT } from "../constants";

const mapState = (state) => ({
  blogs: getBlogsSelector(state),
  status: getBlogsStatusSelector(state),
});

const connector = connect(mapState);

const Blog = ({ blogs, status }) => {
  const isDev = process.env.NODE_ENV === "development";
  const isUnderConstruction = isDev
    ? false
    : process.env.REACT_APP_UNDERCONSTRUCTION === "true";

  useEffect(async () => {
    if (blogs.length === 0) {
      if (
        localStorage.getItem("blogs/limit") == null ||
        localStorage.getItem("blogs/limit") == BLOGS_ITEMS_LIMIT.toString()
      ) {
        localStorage.setItem("blogs/limit", BLOGS_ITEMS_LIMIT.toString());
        store.dispatch(getBlogs({ limit: BLOGS_ITEMS_LIMIT }));
      } else {
        let limit = localStorage.getItem("blogs/limit");
        if (limit !== null) {
          store.dispatch(getBlogs({ limit: Number.parseInt(limit) }));
        }
      }
    }

    store.dispatch(getFeaturedBlogs());
    // Removed blog from the dependency array to avoid circular reference (infinite loop)
  }, []);

  const renderBlogContent = () => {
    return (
      <div className="rn-blog-area ptb--10 bg_color--1">
        <div className="container" style={{ minHeight: "100vh" }}>
          <HeroBlogs />
          <PreviousBlogs />
          <div className="row mt--20">
            <div className="col-lg-12"></div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <PageHelmet />
      {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
      <Breadcrumb title={"Blog"} />
      {isUnderConstruction ? <UnderConstruction /> : renderBlogContent()}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default connector(Blog);
