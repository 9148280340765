// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

import PageScrollTop from './component/PageScrollTop';

import BlogLayout from './layouts/BlogLayout';
import ProjectLayout from './layouts/ProjectLayout';

// Home layout
import Demo from './page-demo/Demo';
import MainDemo from './home/MainDemo';
import Startup from './home/Startup';
import Paralax from './home/Paralax';
import HomePortfolio from './home/HomePortfolio';
import DigitalAgency from './home/DigitalAgency';
import CreativeAgency from './home/CreativeAgency';
import PersonalPortfolio from './home/PersonalPortfolio';
import Business from './home/Business';
import StudioAgency from './home/StudioAgency';
import PortfolioLanding from './home/PortfolioLanding';
import CreativeLanding from './home/CreativeLanding';
import HomeParticles from './home/HomeParticles';
import CreativePortfolio from './home/CreativePortfolio';
import DesignerPortfolio from './home/DesignerPortfolio';
import InteriorLanding from './home/Interior';
import CorporateBusiness from './home/CorporateBusiness';
import InteractiveAgency from './home/InteractiveAgency';

// Dark Home Layout 
import DarkMainDemo from './dark/MainDemo';
import DarkPortfolioLanding from './dark/PortfolioLanding';

// Element Layout
import Service from "./elements/Service";
import ServiceDetails from "./elements/ServiceDetails";
import About from "./elements/About";
import Contact from "./elements/Contact";
import PortfolioDetails from "./elements/portfolio/PortfolioDetails";
import Blog from "./elements/Blog";
// import BlogDetails from "./elements/BlogDetails";
import BlogDetails from "./elements/blog/BlogDetails";
import Error404 from "./elements/error404";


// Blocks Layout

import Team from "./blocks/Team";
import Counters from "./blocks/Counters";
import Testimonial from "./blocks/Testimonial";
import Portfolio from "./blocks/Portfolio";
import VideoPopup from "./blocks/VideoPopup";
import Gallery from "./blocks/Gallery";
import Brand from "./blocks/Brand";
import ProgressBar from "./blocks/ProgressBar";
import ContactForm from "./blocks/ContactForm";
import GoogleMap from "./blocks/GoogleMap";
import Columns from "./blocks/Columns";
import PricingTable from "./blocks/PricingTable";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import store from './redux/store'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker';

import cacheDb from "./cache/db"
import _ from 'lodash';
import moment from 'moment';
import { appInit } from './utils';

class Root extends Component {

    async componentDidMount() {
        // This will invalidate the cache in the local storage or IndexedDB
        await appInit()
    }

    render() {
        return (
            <Provider store={store}>
                <BrowserRouter basename={'/'}>
                    <PageScrollTop>
                        <Routes>
                            {/* Needs to be commented */}
                            {/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={Demo}/> */}
                            {/* <Route exact path={`${process.env.PUBLIC_URL}/main-demo`} component={MainDemo}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/dark-main-demo`} component={DarkMainDemo}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/startup`} component={Startup}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/paralax`} component={Paralax}/> */}

                            {/* Needs to be commented */}
                            {/* <Route exact path={`${process.env.PUBLIC_URL}/digital-agency`} component={DigitalAgency}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/creative-agency`} component={CreativeAgency}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/personal-portfolio`} component={PersonalPortfolio}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/studio-agency`} component={StudioAgency}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/business`} component={Business}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio-home`} component={HomePortfolio}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio-landing`} component={PortfolioLanding}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/creative-landing`} component={CreativeLanding}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/home-particles`} component={HomeParticles}/> */}

                            <Route exact path={`${process.env.PUBLIC_URL}/`} element={<DarkPortfolioLanding />} />

                            {/* Needs to be commented */}
                            {/* <Route exact path={`${process.env.PUBLIC_URL}/designer-portfolio`} element={DesignerPortfolio}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/creative-portfolio`} element={CreativePortfolio}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/interior-landing`} element={InteriorLanding}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/corporate-business`} element={CorporateBusiness}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/interactive-agency`} element={InteractiveAgency}/> */}

                            {/* Element Layot */}
                            {/* <Route exact path={`${process.env.PUBLIC_URL}/service`} element={Service}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/service-details`} element={ServiceDetails}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/contact`} element={Contact}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/about`} element={About}/> */}
                            <Route element={<ProjectLayout />}>
                                <Route path="project" element={<Portfolio />} />
                                <Route path="project/:projectId" element={<PortfolioDetails />} />
                            </Route>
                            {/* <Route exact path={`${process.env.PUBLIC_URL}/portfolio-details/:projectId`} element={<PortfolioDetails />} /> */}
                            {/* <Route exact path={`${process.env.PUBLIC_URL}/blog`} element={<Blog />} />
                            <Route exact path={`${process.env.PUBLIC_URL}/blog-details`} element={<BlogDetails />} />
                            <Route exact path={`${process.env.PUBLIC_URL}/blog/:id`} element={<BlogDetailsNew />} /> */}

                            <Route element={<BlogLayout />}>
                                <Route path="blog" element={<Blog />} />
                                <Route path="blog/:blogId" element={<BlogDetails />} />
                            </Route>
                            {/* Blocks Elements  */}
                            {/* <Route exact path={`${process.env.PUBLIC_URL}/team`} element={Team}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/counters`} element={Counters}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/testimonial`} element={Testimonial}/> */}
                            {/* <Route exact path={`${process.env.PUBLIC_URL}/portfolio`} element={<Portfolio />} /> */}
                            {/* <Route exact path={`${process.env.PUBLIC_URL}/video-popup`} element={VideoPopup}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/gallery`} element={Gallery}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/clint-logo`} element={Brand}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/progressbar`} element={ProgressBar}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/contact-form`} element={ContactForm}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/google-map`} element={GoogleMap}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/columns`} element={Columns}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/pricing-table`} element={PricingTable}/> */}



                            <Route path={`${process.env.PUBLIC_URL}/404`} element={<Error404 />} />
                            <Route element={<Error404 />} />

                        </Routes>
                    </PageScrollTop>
                </BrowserRouter>
            </Provider>
        )
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.register();