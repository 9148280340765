import React ,{ Component }from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor, FiPhone, FiSmartphone } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiMonitor />,
        title: 'Website Development',
        description: 'I specialize in crafting dynamic web applications with a strong focus on React.js and Redux. My proficiency extends to developing SPAs and micro-frontends, allowing for modular and efficient web solutions. Whether it\'s building responsive user interfaces, optimizing state management with Redux, or seamlessly integrating micro-frontends for enhanced scalability and maintainability, I have the skills to create cutting-edge web experiences that meet your specific needs.'
    },
    { 
        icon: <FiSmartphone />,
        title: 'Mobile App Development',
        description: 'My primary focus is on developing cutting-edge mobile applications using React Native. I have a deep understanding of cross-platform development, enabling me to create efficient and feature-rich mobile apps that run seamlessly on both iOS and Android devices.'
    },
    {
        icon: <FiUsers />,
        title: 'Back-End Development',
        description: 'I specialize in creating powerful back-end solutions/ APIs using .NET and Node.js. My expertise includes crafting high-performance APIs and integrating databases, whether it\'s MSSQL for structured data or NoSQL for flexibility and scalability. From secure authentication systems to scalable server architectures, I provide the critical backbone for modern web and mobile applications.'
    }

]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href="#skills">
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
