import React from 'react'
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { dateFormat, textToURL } from '../../../../utils'
import { TAG_COLORS } from "../../../../constants"

const BlogCard = ({ blog, idx, isPrevious }) => {
    return (
        <div className={`item-${idx + 1}`} key={idx}>
            <Link className="card" to={`/blog/${textToURL(blog.fields.name)}`}>
                <div className="thumb" style={{ backgroundImage: `url(${blog.fields.images[0].fields.file.url})` }} />
                <article>
                    <h1>{blog.fields.name}</h1>
                    {!isPrevious && <p>{blog.fields.shortDescription}</p>}
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span style={{
                            backgroundColor: TAG_COLORS.find(c => c.tag === blog.fields.blogType).background,
                            color: TAG_COLORS.find(c => c.tag === blog.fields.blogType).color
                        }}>{blog.fields.blogType}</span>
                        <span style={{color: 'black'}}>{dateFormat(blog.fields.createdDate)}</span>
                    </div>
                </article>
            </Link>
        </div>
    )
}

BlogCard.propTypes = {
    blog: PropTypes.object.isRequired,
    idx: PropTypes.number.isRequired,
    isPrevious: PropTypes.bool
};

BlogCard.defaultProps = {
    isPrevious: false
};

export default BlogCard
