import React, { useEffect } from "react";
import { connect } from "react-redux";
import { FiClock, FiUser } from "react-icons/fi";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useParams } from "react-router-dom";
import SyntaxHighlighter from "react-syntax-highlighter";
import { vs2015 } from "react-syntax-highlighter/dist/esm/styles/hljs";

import BlogDetailsPageHelmet from "../../component/common/Helmet/BlogDetails";
import BlogDetailsPlaceholderImage from "../../component/common/PlaceholderImage/blog/BlogDetailsPlaceholderImage";
import Footer from "../../component/footer/Footer";
import SocialShare from "../../component/common/SocialShare";
import store from "../../redux/store";
import { getBlogByTitle } from "../../redux/actions/blogs";
import {
  getBlogSelector,
  getBlogStatusSelector,
} from "../../redux/selectors/blogs";
import { dateFormat, urlToText } from "../../utils";

const website_url = "localhost";

const Bold = ({ children }) => <p className="bold">{children}</p>;

const Text = ({ children }) => <p className="align-center">{children}</p>;

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <span className="bold-highlight">{text}</span>,
    [MARKS.CODE]: (text) => (
        <SyntaxHighlighter
          language="typescript"
          style={vs2015}
          customStyle={{ borderRadius: "10px", fontSize: "20px" }}
        >
          {text}
        </SyntaxHighlighter>
      )
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: (node) => 
      // render the asset accordingly
       (
        <div style={{ marginBottom: "40px" }}>
          <img src={node.data.target.fields.file.url} alt="My image alt text" />
        </div>
      )
    ,
    [INLINES.HYPERLINK]: ({ data }, children) => (
        <a
          href={data.uri}
          target={`${data.uri.startsWith(website_url) ? "_self" : "_blank"}`}
          rel={`${
            data.uri.startsWith(website_url) ? "" : "noopener noreferrer"
          }`}
          style={{ color: "blue" }}
        >
          {children}
        </a>
      ),
      [INLINES.EMBEDDED_ENTRY]: ({ data }, children) => {
        if(data) {
          return <iframe src={data?.target?.fields?.link}
                    style={{width:'100%', height:'500px', border: '0', borderRadius: '4px', overflow:'hidden'}}
                    title="react debounce"
                    allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking"
                    sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
                  />;
        } else {
          return <></>;
        }

      }
  },
  renderText: (text) => text.replace("!", "?"),
};

const mapState = (state) => ({
    blog: getBlogSelector(state),
    blogStatus: getBlogStatusSelector(state),
  });

const connector = connect(mapState);

const BlogDetails = ({ blog, blogStatus }) => {
  const { blogId } = useParams();

  useEffect(() => {
    store.dispatch(getBlogByTitle(urlToText(blogId)));
  }, [blogId]);

  if (blogStatus === "succeeded") {
    return (
      <React.Fragment>
        <BlogDetailsPageHelmet
          title={blog.fields.name}
          description={blog.fields.shortDescription}
          image={blog.fields.images[0].fields.file.url}
          keywords={blog.fields.keywords}
        />
        {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 bg_image"
          style={{
            backgroundImage: `url(${blog.fields.images[0].fields.file.url})`,
          }}
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-single-page-title text-center pt--100">
                  {/* <h2 className="title theme-gradient">The Home of the Future <br /> Could Bebes</h2> */}
                  <h2 className="title theme-gradient">{blog.fields.name}</h2>
                  <ul className="blog-meta d-flex justify-content-center align-items-center">
                    {/* <li><FiClock />May 18, 2020</li> */}
                    <li>
                      <FiClock />
                      {dateFormat(blog.fields.createdDate)}
                    </li>
                    <li>
                      <FiUser />
                      Dushan
                    </li>
                    {/* <li><FiMessageCircle />15 Comments</li>
                    <li><FiHeart />Like</li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}
        <SocialShare />

        {/* Start Blog Details */}
        <div className="rn-blog-details pt--50 pb--70 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-wrapper">
                  <div className="inner">
                    {documentToReactComponents(blog.fields.content, options)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  } else {
    return <BlogDetailsPlaceholderImage />;
  }
};

export default connector(BlogDetails);
