export const getBlogsSelector = (state) => state.blogs.items

export const getBlogsStatusSelector = (state) => state.blogs.status

export const getFeaturedBlogsSelector = (state) => state.blogs.featuredItems

export const getFeaturedBlogsStatusSelector = (state) => state.blogs.featuredStatus

export const getBlogSelector = (state) => state.blogs.item

export const getBlogStatusSelector = (state) => state.blogs.blogStatus