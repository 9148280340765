import React from 'react'
import PropTypes from 'prop-types'
import { Outlet } from "react-router-dom";

import Header from "../component/header/HeaderProject";

const ProjectLayout = props => {
    return (
        <>
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            <Outlet />
        </>
    )
}

ProjectLayout.propTypes = {}

export default ProjectLayout