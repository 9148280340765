import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { connect } from 'react-redux'

import store from '../../redux/store'
import { getProjects } from '../../redux/actions/projects'
import { getNonConceptProjectsSelector, getProjectsStatusSelector } from "../../redux/selectors/projects";
import ProjectCard from "../../component/common/ProjectCard";
import ProjectListBlockPlaceholderImage from "../../component/common/PlaceholderImage/project/ProjectListBlock"

const mapState = (state) => ({
    projects: getNonConceptProjectsSelector(state),
    status: getProjectsStatusSelector(state)
})

const connector = connect(mapState)

const PortfolioList = ({ column, styevariation, projects, status, ...props }) => {
    const [rows, setRows] = useState(1);
    const { item } = props;

    useEffect(() => {
        if(projects.length === 0){
            store.dispatch(getProjects({ limit: 8, skip: 0 }))
        } else {
            const _rows = calculateRows(projects);
            setRows(_rows);
        }
    }, [projects])

    const calculateRows = (_projects) => {
        return _projects.length > 3 ? Number(_projects.length/3) : 1
    }

    if (status === 'succeeded') {
        return (
            <React.Fragment>
                {projects.slice(0, item > 0 ? item : projects.length).map((project, index) => {
                    return <ProjectCard key={index} column={column} index={index} project={project} styevariation={styevariation} />
                })}
            </React.Fragment>
        )
    } else {
        return <ProjectListBlockPlaceholderImage rows={rows} height={240} />
    }
}

export default connector(PortfolioList);