export const TAG_COLORS = [
    {
        "tag": "React",
        "background": "#0A2647",
        "color": "#fff"
    },
    {
        "tag": "JavaScript",
        "background": "#FED049",
        "color": "#000"
    },
    {
        "tag": "Back-End",
        "background": "#FC7300",
        "color": "#fff"
    },
    {
        "tag": "Other",
        "background": "#EB455F",
        "color": "#fff"
    },
]

export const BLOGS_ITEMS_LIMIT = 4