import React, { Component } from "react";
import { Helmet } from 'react-helmet'

class BlogPageHelmet extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Dushan Ranasinghage Blog</title>
                    <meta name="description" content="Join and read articles on JavaScript, TypeScript, React, Testing, Career, and more." />
                    <meta name="keywords" content="JavaScript, TypeScript, React, Testing, Career, Software Development, Dushan Ranasinghage Blog" />
                    <meta name="image" content="https://www.dushandev.com/assets/images/about/og-default.png" />
                    <meta property="og:url" content="https://dushandev.com/blog" />
                    <meta property="og:title" content="The Dushan Ranasinghage Blog" />
                    <meta property="og:description" content="Join and read articles on JavaScript, TypeScript, React, Testing, Career, and more." />
                    <meta property="og:image" content="https://www.dushandev.com/assets/images/about/og-default.png" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:creator" content="@dushandev" />
                    <meta name="twitter:site" content="@dushandev" />
                    <meta name="twitter:title" content="The Dushan Ranasinghage Blog" />
                    <meta name="twitter:description" content="Join and read articles on JavaScript, TypeScript, React, Testing, Career, and more." />
                    <meta name="twitter:alt" content="The Dushan Ranasinghage Blog" />
                    <link rel="canonical" href="https://dushandev.com/blog" />

                    {/* <link rel="alternate" type="application/rss+xml" title="Dushan Ranasinghage Blog" href="/blog/rss.xml" /> */}
                </Helmet>
            </React.Fragment>
        )
    }
}


export default BlogPageHelmet;
