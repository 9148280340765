import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import contentfulClient from "../../configs/contentful";
import { rightOuterJoin } from "../../utils";

import cacheDb from "../../cache/db"

// const transform = async (cache, results) => {
//     if (cache) {
//         const newBlogs = rightOuterJoin(cache, results);
//         const allBlogs = _.concat(cache, newBlogs);
//         await cacheDb.setItem('blogs/get', JSON.stringify(allBlogs));
//         return allBlogs;
//     } else {
//         await cacheDb.setItem('blogs/get', JSON.stringify(results));
//         return results;
//     }
// }

// export const getBlogs = createAsyncThunk(
//     'blogs/get',
//     async ({ limit, skip }) => {
//         try {
//             const cachedBlogs = await cacheDb.getItem('blogs/get');
//             const fetchBlogsResults = await contentfulClient.getEntries({
//                 content_type: "blog",
//                 order: 'sys.createdAt',
//                 "metadata.tags.sys.id[nin]": "featured",
//                 limit: limit,
//                 skip: skip
//             });

//             const resultsInCache = _.differenceWith(fetchBlogsResults.items, JSON.parse(cachedBlogs), _.isEqual).length === 0;

//             if(_.isEmpty(fetchBlogsResults.items)) return JSON.parse(cachedBlogs);

//             if (skip === 0) {
//                 await localStorage.setItem('blogs/skip', skip);
//                 return await transform(JSON.parse(cachedBlogs), fetchBlogsResults.items);
//             } else {
//                 await localStorage.setItem('blogs/skip', skip);
//                 if (resultsInCache) {
//                     return JSON.parse(cachedBlogs);
//                 } else {
//                     return await transform(JSON.parse(cachedBlogs), fetchBlogsResults.items);
//                 }
//             }
//         } catch (e) {
//             return []
//         }
//     }
// )

export const getBlogs = createAsyncThunk(
    'blogs/get',
    async ({ limit, skip = 0 }) => {
        try {
            const fetchBlogsResults = await contentfulClient.getEntries({
                content_type: "blog",
                order: 'sys.createdAt',
                "metadata.tags.sys.id[nin]": "featured",
                "fields.isActive": true,
                limit: limit,
                skip: skip
            });

            if(fetchBlogsResults.items.length > 0){
                await localStorage.setItem('blogs/skip', skip)
            }

            return fetchBlogsResults.items;
        } catch (e) {
            return [];
        }
    }
)

/**
 * GET Featured Blogs
 */
export const getFeaturedBlogs = createAsyncThunk(
    'blogs/getFeatured',
    async () => {
        try {
            const results = await contentfulClient.getEntries({
                content_type: "blog",
                limit: 3,
                "metadata.tags.sys.id[all]": "featured",
                "fields.isActive": true,
            })
            return results.items
        } catch (e) {
            return []
        }
    }
)

export const getBlogsByTag = createAsyncThunk(
    'blogs/getByTag',
    async () => {
        try {
            const results = await contentfulClient.getEntries({
                content_type: "blog",
                limit: 3,
                "metadata.tags.sys.id[all]": "featured",
                "fields.isActive": true,
            })
            return results.items
        } catch (e) {
            return []
        }
    }
)

export const getBlog = createAsyncThunk(
    'blog/get',
    async (blogId) => {
        const result = await contentfulClient.getEntry(blogId)
        return result
    }
)


export const getBlogByTitle = createAsyncThunk(
    'blog/get',
    async (title) => {
        const results = await contentfulClient.getEntries({
            content_type: "blog",
            "fields.name[match]": title,
            // "fields.isActive": true,
        })
        return results.items[0]
    }
)
