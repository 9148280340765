import React, { useState, useEffect } from "react";
import ModalVideo from 'react-modal-video';
import { FaTwitter, FaGithub, FaGooglePlay, FaLinkedinIn, FaStackOverflow } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { FiChevronUp } from "react-icons/fi";
import { connect, useDispatch } from 'react-redux'
import ClipLoader from "react-spinners/ClipLoader";
import { useParams } from 'react-router-dom';

import PageHelmet from "../../component/common/Helmet";
import Header from "../../component/header/Header";
import BlogDetailsPlaceholderImage from '../../component/common/PlaceholderImage/blog/BlogDetailsPlaceholderImage';
import Footer from "../../component/footer/Footer";
import FooterTwo from "../../component/footer/FooterTwo";

import store from '../../redux/store'
import { getProject } from '../../redux/actions/projects'
import { getProjectSelector, getProjectStatusSelector } from "../../redux/selectors/projects";
import { withRouter } from '../../hooks/withRouter';

const SocialShare = [
    { Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/in/dushan-ranasinghage' },
    { Social: <FaGithub />, link: 'https://github.com/dushan-ranasinghage' },
    { Social: <FaStackOverflow />, link: 'https://stackoverflow.com/users/10263108/dushan-ranasinghage' },
    { Social: <FaGooglePlay />, link: 'https://play.google.com/store/apps/developer?id=dExTeR' },
    { Social: <FaTwitter />, link: 'https://twitter.com/dushandev' },
]

const Bold = ({ children }) => <span className="bold">{children}</span>;

const Text = ({ children }) => <p className="align-center">{children}</p>;

const options = {
    renderNode: {
        [BLOCKS.UL_LIST]: (node, children) => {
            return (<ul>{children}</ul>)
        },
        [BLOCKS.OL_LIST]: (node, children) => {
            return (<ol>{children}</ol>)
        },
        [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
        [INLINES.EMBEDDED_ENTRY]: (node, children) => {
            // target the contentType of the EMBEDDED_ENTRY to display as you need
            if (node.data.target.sys.contentType.sys.id === "blogPost") {
                return (
                    <a href={`/blog/${node.data.target.fields.slug}`}>            {node.data.target.fields.title}
                    </a>
                );
            }
        },
        [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
            // target the contentType of the EMBEDDED_ENTRY to display as you need
            if (node.data.target.sys.contentType.sys.id === "codeBlock") {
                return (
                    <pre>
                        <code>{node.data.target.fields.code}</code>
                    </pre>
                );
            }

            if (node.data.target.sys.contentType.sys.id === "videoEmbed") {
                return (
                    <iframe
                        src={node.data.target.fields.embedUrl}
                        height="100%"
                        width="100%"
                        frameBorder="0"
                        scrolling="no"
                        title={node.data.target.fields.title}
                        allowFullScreen={true}
                    />
                );
            }
        },

        [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
            // render the EMBEDDED_ASSET as you need
            return (
                <img
                    src={`https://${node.data.target.fields.file.url}`}
                    height={"100%"}
                    width={node.data.target.fields.file.details.image.width}
                    alt={node.data.target.fields.description}
                />
            );
        },
    },
};

const mapState = (state) => ({
    project: getProjectSelector(state),
    projectStatus: getProjectStatusSelector(state)
})

const connector = connect(mapState)

const PortfolioDetails = ({ project, projectStatus, ...props }) => {
    const [openModal, setOpenModal] = useState(false)
    const { projectId } = useParams();

    useEffect(() => {
        store.dispatch(getProject(projectId))
    }, [projectId])

    if (projectStatus === 'succeeded') {
        return (
            <React.Fragment>
                {/* <PageHelmet pageTitle='Dushan Dev' /> */}

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190" data-black-overlay="7" style={{ backgroundImage: `url(${project.fields.images[0].fields.file.url})` }}>
                    {/* <img className={`thumbnail`} src={this.props.location.state.fields.images[0].fields.file.url} alt={this.props.location.state.fields.images[0].fields.file.title} /> */}
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">{project.fields.name}</h2>
                                    <p>{project.fields.shortDescription}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        {/* <h2>Trydo</h2> */}
                                        {/* <p className="subtitle">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p> */}
                                        {/* <Markdown style={{ textAlign: 'justify' }}>{this.props.location.state.fields?.content}</Markdown> */}
                                        {documentToReactComponents(project.fields?.content, options)}

                                        <div className="portfolio-view-list d-flex flex-wrap">
                                            {/* <div className="port-view">
                                                <span>Branch</span>
                                                <h4>Ability</h4>
                                            </div> */}

                                            <div className="port-view">
                                                <span>Project Type</span>
                                                <h4>{project.fields.projectType}</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Status</span>
                                                <h4>{project.fields.projectStatus}</h4>
                                            </div>

                                            {project.fields && project.fields.url ?
                                                <div className="port-view">
                                                    <span>Go to Project</span>
                                                    <h4 style={{ color: '#00FF00' }}><a href={project.fields.url} target="blank">Click</a></h4>
                                                </div> : <></>
                                            }
                                        </div>

                                        <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                                            <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
                                                {SocialShare.map((val, i) => (
                                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <div className="portfolio-thumb-inner">
                                        <div className="thumb position-relative mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-03.jpg" alt="Portfolio Images"/>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                        
                                        <div className="thumb mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-02.jpg" alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb">
                                            <img src="/assets/images/portfolio/portfolio-big-01.jpg" alt="Portfolio Images"/>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Related Work */}
                <div className="portfolio-related-work pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="theme-color font--18 fontWeight600">Related Work</span>
                                    <h2>More Projects</h2>
                                </div>
                            </div> */}
                        </div>
                        <div className="row mt--10">
                            {/* Start Single Portfolio */}
                            {/* <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="/portfolio-details">
                                            <img src="/assets/images/portfolio/related-image-01.jpg" alt="Portfolio-images"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="/portfolio-details">Digital Analysis</a></h4>
                                        <span className="category">Technique</span>
                                    </div>
                                </div>
                            </div> */}
                            {/* End Single Portfolio */}
                            {/* Start Single Portfolio */}
                            {/* <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="/portfolio-details">
                                            <img src="/assets/images/portfolio/related-image-02.jpg" alt="Portfolio-images"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="/portfolio-details">Plan Management</a></h4>
                                        <span className="category">PLANNING</span>
                                    </div>
                                </div>
                            </div> */}
                            {/* End Single Portfolio */}
                        </div>
                    </div>
                </div>
                {/* End Related Work */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterTwo />


            </React.Fragment>
        )
    } else {
        return <BlogDetailsPlaceholderImage />
    }
}
export default withRouter(connector(PortfolioDetails));
