import React from "react";

const UnderConstruction = () => {
  return (
    <div className="rn-blog-area ptb--10 bg_color--1">
      <div className="container" style={{ minHeight: "100vh" }}>
        <div className="row mt--20">
          <div className="col-lg-12">
            <center><h1>Under Construction</h1></center>
            <center>
              <img
                className="w-50"
                src="/assets/images/portfolio/big/3675500-removebg.png"
                alt="coming soon"
                loading="lazy"
              />
            </center>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnderConstruction;
