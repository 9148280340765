import { createSlice, current } from '@reduxjs/toolkit'

import { getProjects, getProject } from '../actions/projects'

// status: 'idle' | 'pending' | 'succeeded' | 'failed',
const INITIAL_STATE = {
  items: [],
  status: 'idle',
  item: null,
  projectStatus: 'idle',
}

export const counterSlice = createSlice({
  name: 'projects',
  initialState: INITIAL_STATE,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getProjects.pending, (state, action) => {
      state.status = 'pending'
    });
    builder.addCase(getProjects.fulfilled, (state, action) => {
      state.items = action.payload
      state.status = 'succeeded'
    });
    builder.addCase(getProjects.rejected, (state, action) => {
      state.status = 'failed'
    });
    builder.addCase(getProject.pending, (state, action) => {
      state.projectStatus = 'pending'
    });
    builder.addCase(getProject.fulfilled, (state, action) => {
      state.item = action.payload
      state.projectStatus = 'succeeded'
    });
    builder.addCase(getProject.rejected, (state, action) => {
      state.projectStatus = 'failed'
    });
  },
})

export default counterSlice.reducer