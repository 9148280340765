import React, { useState, useEffect  } from 'react'
import PropTypes from "prop-types";

import BlogCardPlaceholderImage from '../blog/BlogCardPlaceholderImage'

const ProjectListBlockPlaceholderImage = ({ rows = 1, height = 400 }) => {
    const [arr, setArr] = useState([]);
    
    useEffect(() => {
        const genArr = generateArr(rows);
        setArr(genArr);
    }, [rows]);

    const generateArr = (_rows) => {
        const arr = [];
        for (let index = 0; index < _rows; index++) {
            arr[index] = _rows;
        }

        return arr;
    };

    const Row = () => {
        return (
            <div className='container' style={{ marginTop:'0px' }}>
                <div className='row'>
    
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                        <div
                            className="placeholder fade-in-left-right"
                            style={{
                                margin: '10px',
                                borderRadius: '5px',
                                height: `${height}px`,
                                width: '100%'
                            }}
                        >
                            <BlogCardPlaceholderImage showWordLines={false} />
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                        <div
                            className="placeholder fade-in-left-right"
                            style={{
                                margin: '10px',
                                borderRadius: '5px',
                                height: `${height}px`,
                                width: '100%'
                            }}
                        >
                            <BlogCardPlaceholderImage showWordLines={false} />
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                        <div
                            className="placeholder fade-in-left-right"
                            style={{
                                margin: '10px',
                                borderRadius: '5px',
                                height: `${height}px`,
                                width: '100%'
                            }}
                        >
                            <BlogCardPlaceholderImage showWordLines={false} />
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    return arr.map((_row, idx) => {
        return <Row key={idx} />
    });
}

BlogCardPlaceholderImage.propTypes = {
    rows: PropTypes.number,
    height: PropTypes.number
}

export default ProjectListBlockPlaceholderImage