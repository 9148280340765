import React, { useEffect, useState, Fragment } from "react";
import { connect, useDispatch } from 'react-redux'
import ClipLoader from "react-spinners/ClipLoader";

import { getBlogs } from '../../redux/actions/blogs'
import store from '../../redux/store'
import { getBlogsSelector, getBlogsStatusSelector } from "../../redux/selectors/blogs";

import BlogContent from "./BlogContent";

const mapState = (state) => ({
    blogs: getBlogsSelector(state),
    status: getBlogsStatusSelector(state)
})

const connector = connect(mapState)

const BlogList = ({ blogs, status }) => {

    console.log("Blogs", blogs, status)

    if (status === 'succeeded') {
        return (
            <Fragment>
                <div className="row">
                    {/* {blogs.map((value , i ) => {
                        let imageUrl = value.attributes.HeroImage.data.attributes.url
                        let imageCaption = value.attributes.HeroImage.data.attributes.name
                        return (
                           <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                               <div className="blog blog-style--1">
                                   <div className="thumbnail">
                                       <a href="/blog-details">
                                           <img className="w-100" src={imageUrl} alt={imageCaption}/>
                                       </a>
                                   </div>
                                   <div className="content">
                                       <p className="blogtype">{value.attributes.Category}</p>
                                       <h4 className="title"><a href="/blog-details">{value.attributes.Title}</a></h4>
                                       <div className="blog-btn">
                                           <a className="rn-btn text-white" href="/blog-details">Read More</a>
                                       </div>
                                   </div>
                               </div>
                           </div>
                        )})} */}
                </div>
            </Fragment>
        );
    } else {
        return (
            <div className="spinner"><ClipLoader /></div>
        )
    }

}
export default connector(BlogList);