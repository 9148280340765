import { combineReducers } from "@reduxjs/toolkit";

import projectsReducer from '../reducers/projectsSlice'
import blogReducer from '../reducers/blogsSlice'

const rootReducer = combineReducers({
    projects: projectsReducer,
    blogs: blogReducer,
})

export default rootReducer