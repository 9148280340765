import _ from 'lodash';
import moment from 'moment';

import cacheDb from "../cache/db"

// September 4, 1986
export const dateFormat = (date) => {
    return moment(date).format('LL');
}

export const rightOuterJoin = (left, right) => {
    return right.filter((x) => !left.includes(x))
}

const dateDiff = (first, now) => {
    let dateNow = moment(now); //todays date
    let firstDate = moment(first); // another date
    let duration = moment.duration(dateNow.diff(firstDate));
    let days = duration.asDays();
    return Math.floor(days)
  }

export const appInit = async () => {
    const initDateLocalStorage = localStorage.getItem('__appInitDate');
    const initDateIndexedDB = await cacheDb.getItem('__appInitDate');

    if (_.isEmpty(initDateLocalStorage) && _.isEmpty(initDateIndexedDB)) {
        localStorage.setItem('__appInitDate', new Date());
        await cacheDb.setItem('__appInitDate', new Date());
    } else {
        const differenceLS = dateDiff(new Date(initDateLocalStorage), new Date())
        const differenceIDB = dateDiff(new Date(initDateIndexedDB.toString()), new Date())

        if (differenceIDB > 5) {
            localStorage.clear()
        }

        if (differenceLS > 4) {
            await cacheDb.clear()
        }
    }
}

export const textToURL = (title) => {
    return title.toLowerCase().replaceAll(" ", "-");
}

export const urlToText = (url) => {
    return url.replaceAll("-", " ");
}

// export const arrangeFeaturedBlogs = (blogs) => {
//     let newFeaturedblogs = [];
//     const topPost = blogs.find(b => b.metadata.tags.)
//     return blogs;
// }

export const compareFn = (a, b) => {
    if (a.fields.id > b.fields.id) {
      return -1;
    }
    if (a.fields.id < b.fields.id) {
      return 1;
    }
    // a must be equal to b
    return 0;
  }