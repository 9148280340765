import React from 'react'
import PropTypes from 'prop-types'

const PlaceholderImage = ({ arr, height, marginTop }) => {
    return (
        <div className='container' style={{ marginTop: marginTop ? `${marginTop}px` : '0' }}>
            <div className='row'>
                {arr.map((col, idx) => {
                    return (
                        <div className={`col-${col}`} key={idx}>
                            <div
                                className="placeholder fade-in-left-right"
                                style={{
                                    // backgroundColor: '#f0f0f0',
                                    margin: '10px',
                                    borderRadius: '5px',
                                    height: `${height}px`,
                                    width: '100%'
                                }}
                            >
                                <div className='container h-100'>
                                    <div className='row h-100'>
                                        <div className='col-12 h-100'>
                                            <div
                                                className="placeholder fade-in-left-right"
                                                style={{
                                                    backgroundColor: '#f0f0f0',
                                                    margin: '10px',
                                                    borderRadius: '5px',
                                                    height: '40%',
                                                    width: '100%'
                                                }}
                                            >
                                            </div>
                                            <div
                                                className="placeholder fade-in-left-right"
                                                style={{
                                                    backgroundColor: '#f0f0f0',
                                                    margin: '10px',
                                                    borderRadius: '5px',
                                                    height: '2%',
                                                    width: '100%'
                                                }}
                                            >
                                            </div>
                                            <div
                                                className="placeholder fade-in-left-right"
                                                style={{
                                                    backgroundColor: '#f0f0f0',
                                                    margin: '10px',
                                                    borderRadius: '5px',
                                                    height: '2%',
                                                    width: '100%'
                                                }}
                                            >
                                            </div>
                                            <div
                                                className="placeholder fade-in-left-right"
                                                style={{
                                                    backgroundColor: '#f0f0f0',
                                                    margin: '10px',
                                                    borderRadius: '5px',
                                                    height: '2%',
                                                    width: '80%'
                                                }}
                                            >
                                            </div>
                                            <div
                                                className="placeholder fade-in-left-right"
                                                style={{
                                                    backgroundColor: '#f0f0f0',
                                                    margin: '10px',
                                                    borderRadius: '5px',
                                                    height: '2%',
                                                    width: '80%'
                                                }}
                                            >
                                            </div>
                                            <div
                                                className="placeholder fade-in-left-right"
                                                style={{
                                                    backgroundColor: '#f0f0f0',
                                                    margin: '10px',
                                                    borderRadius: '5px',
                                                    height: '2%',
                                                    width: '80%'
                                                }}
                                            >
                                            </div>
                                            <div
                                                className="placeholder fade-in-left-right"
                                                style={{
                                                    backgroundColor: '#f0f0f0',
                                                    margin: '10px',
                                                    borderRadius: '5px',
                                                    height: '2%',
                                                    width: '80%'
                                                }}
                                            >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

PlaceholderImage.propTypes = {
    arr: PropTypes.array,
    height: PropTypes.number,
    marginTop: PropTypes.number
}

export default PlaceholderImage