import React from 'react'
import { connect } from 'react-redux'

import BlogCard from '../BlogCard';
import PlaceholderImage from '../../../../component/common/PlaceholderImage';
import { getFeaturedBlogsSelector, getFeaturedBlogsStatusSelector } from "../../../../redux/selectors/blogs";

const mapState = (state) => ({
    featuredItems: getFeaturedBlogsSelector(state),
    featuredStatus: getFeaturedBlogsStatusSelector(state)
})

const connector = connect(mapState)

const HeroBlogs = ({ featuredItems, featuredStatus }) => {
    return (
        <section id="hero-blogs">
            <h2>Latest Blogs</h2>
            <HeroBlogList
                featuredItems={featuredItems}
                featuredStatus={featuredStatus}
            />
        </section>
    )
}

export default connector(HeroBlogs)

/**
 * 
 * @param {Array} featuredItems 
 * @returns 
 */
const HeroBlogList = ({ featuredItems, featuredStatus }) => {
    if (featuredStatus === 'succeeded') {
        return (
            <div className="band">{featuredItems.map((blog, idx) => {
                return <BlogCard blog={blog} idx={idx} />
            })}</div>
        )
    } else {
        return <PlaceholderImage arr={[6, 3, 3]} height={400} />
    }
}