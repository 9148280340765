import { compareFn } from './../../utils'

export const getProjectsSelector = (state) => state.projects.items

export const getProjectsDescSelector = (state) => state.projects.items.sort(compareFn);

export const getProjectsStatusSelector = (state) => state.projects.status

export const getProjectSelector = (state) => state.projects.item

export const getProjectStatusSelector = (state) => state.projects.projectStatus

export const getConceptProjectsSelector = (state) => state.projects.items.filter((item) => item.fields.isConcept)

export const getNonConceptProjectsSelector = (state) => state.projects.items.filter((item) => !item.fields.isConcept)