import React, { useEffect, useState, Fragment } from "react";
import { connect, useDispatch } from 'react-redux'
import ClipLoader from "react-spinners/ClipLoader";
import Button from 'react-bootstrap/Button';

import BlogCard from '../BlogCard';
import BlogListBlock from '../../../../component/common/PlaceholderImage/blog/BlogListBlock';
import { getBlogs, getFeaturedBlogs } from '../../../../redux/actions/blogs'
import store from '../../../../redux/store'
import { getBlogsSelector, getBlogsStatusSelector } from "../../../../redux/selectors/blogs";

import { BLOGS_ITEMS_LIMIT } from '../../../../constants'

const mapState = (state) => ({
    blogs: getBlogsSelector(state),
    status: getBlogsStatusSelector(state)
})

const connector = connect(mapState)

const PreviousBlogs = ({ blogs, status }) => {
    const [show, setShow] = useState(true);
    const [showMore, setShowMore] = useState(false);

    if (!show) return null;

    return (
        <section id="previous-blogs">
            <h3>Previous Blogs</h3>
            <PreviousBlogList blogs={blogs} status={status} setShow={setShow} setShowMore={setShowMore} />
            <center>
                {showMore && <Button
                    variant="danger"
                    style={{ width: '120px', boxShadow: '0 0 1px 0px #000' }}
                    onClick={() => {
                        let limit = localStorage.getItem("blogs/limit")
                        if (limit !== null) {
                            localStorage.setItem("blogs/limit", (Number.parseInt(limit) + BLOGS_ITEMS_LIMIT).toString())
                            store.dispatch(getBlogs({ limit: Number.parseInt(limit) + BLOGS_ITEMS_LIMIT }))
                        }
                    }}
                >
                    Show More
                </Button>}
            </center>
        </section>
    )
}

export default connector(PreviousBlogs)


/**
 * 
 * @param {blogs, status} param0 
 * @returns 
 */
const PreviousBlogList = ({ blogs, status, setShow, setShowMore }) => {
    useEffect(() => {
        if(status === 'succeeded' && blogs.length <= 0){
            setShow(false);
        }

        if(status === 'succeeded' && blogs.length > 4){
            setShowMore(true);
        }

    }, [blogs, status])

    if (status === 'succeeded') {
        return (
            <div className="band">
                {blogs.map((blog, idx) => {
                    return <BlogCard blog={blog} idx={idx + 1} isPrevious={true} />
                })}
            </div>
        )
    } else {
        return <BlogListBlock />
    }
}