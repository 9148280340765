import React, { useEffect } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/BreadcrumbCommon";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import PortfolioList from "../elements/portfolio/PortfolioList";
import PortfolioList2 from "../elements/portfolio/PortfolioList2";
import FooterTwo from "../component/footer/FooterTwo";

import { getProjects } from "../redux/actions/projects";
import store from "../redux/store";

const Portfolio = () => {
  useEffect(() => {
    store.dispatch(getProjects({ limit: 8, skip: 0 }));
  }, []);

  return (
    <>
      <PageHelmet pageTitle="My Projects" />

      <Breadcrumb title={"Portfolio"} />

      <main className="page-wrapper">
        {/* Start Portfolio Area */}
        <div className="portfolio-area ptb--60 bg_color--5">
          <div className="portfolio-sacousel-inner">
            <div className="container">
              <h2>My Projects</h2>
              <p>
                These are some of my personal and freelance projects that I have
                been working on over past years.{" "}
              </p>
              <div className="row mb--50">
                <PortfolioList
                  styevariation="text-left mt--40"
                  column="col-lg-4 col-md-6 col-sm-6 col-12"
                  item={0}
                />
              </div>
              <h3>Concept Projects</h3>
              <p>
                These are some of my concept projects that show case React or other architectural concepts in the web development.{" "}
              </p>
              <div className="row mb--50">
                <PortfolioList2
                  styevariation="text-left mt--40"
                  column="col-lg-4 col-md-6 col-sm-6 col-12"
                  item={0}
                />
              </div>
            </div>
          </div>
        </div>
      </main>

      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>

      <FooterTwo />
    </>
  );
};

export default Portfolio;
