import { createAsyncThunk } from "@reduxjs/toolkit";
import contentfulClient from "../../configs/contentful";

import cacheDb from "../../cache/db"

export const getProjects = createAsyncThunk(
    'projects/get',
    async ({ limit, skip }) => {
        try {
            const results = await contentfulClient.getEntries({
                    content_type: "project",
                    order: '-fields.id',
                    // "metadata.tags.sys.id[nin]": "featured",
                    limit: limit,
                    skip: skip
                })
            return results.items
        } catch (e) {
            return []
        }
    }
)

export const getProject = createAsyncThunk(
    'project/get',
    async (projectId) => {
        const result = await contentfulClient.getEntry(projectId)
        return result
    }
)


// const getProjects = () => client.getEntries({content_type: "project"}).then(response => response.items);
  
// const getSingleProject = slug =>
//   client
//     .getEntries({
//       "fields.slug": slug,
//       content_type: "blogPost"
//     })
//     .then(response => response.items);

// const getMediaByEntryId = entryId => {
//   client.getEntry(entryId).then(function (entry) {
//     // logs the entry metadata
//     console.log(entry.sys);
  
//     // logs the field with ID title
//     console.log(entry.fields.productName);
//   });
// }