import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "./ProjectCard.scss";

const ProjectCard = ({ column, index, styevariation, project }) => {
  return (
    <div className={`${column}`} key={index}>
      <div className={`portfolio ${styevariation}`}>
        <div className="thumbnail-inner">
          <Link className="link-overlay" to={`/project/${project.sys.id}`}>
            <img
              className={`thumbnail`}
              src={project.fields.images[0].fields.file.url}
              alt={project.fields.images[0].fields.file.title}
            />
            <div className={`bg-blr-image`}></div>
            <div className="content-project-card">
              <div className="inner">
                <Link to={`/project/${project.sys.id}`}>
                  <p>{project.fields.projectType}</p>
                  <h4>
                    <a href="/portfolio-details">{project.fields.name}</a>
                  </h4>
                  {/* <div className="portfolio-button">
                <Link className="rn-btn" to={`/project/${project.sys.id}`}>
                  View Details
                </Link>
              </div> */}
                </Link>
              </div>
            </div>
          </Link>
        </div>

        {/* <Link className="link-overlay" to={`/portfolio-details?projectId=${index}`}></Link> */}
      </div>
    </div>
  );
};

ProjectCard.propTypes = {
  column: PropTypes.any,
  index: PropTypes.number,
  styevariation: PropTypes.any,
  project: PropTypes.any,
};

// ProjectCard.defaultProps  = {
// };

export default ProjectCard;
