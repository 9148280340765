import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        let 
        tab1 = "Main skills",
        // tab2 = "Awards",
        tab3 = "Experience",
        tab4 = "Education & Certification";
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        {/* <Tab>{tab2}</Tab> */}
                                        <Tab>{tab3}</Tab>
                                        <Tab>{tab4}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a href="#">Web and Mobile Application Development <span> - Development</span></a>
                                                    <span style={{color:'#89CFF0'}}>React, React Native, JavaScript (ES6), TypeScript, HTML5, CSS3, SASS</span>
                                                </li>
                                                <li>
                                                    <a href="#">Back-End Development<span> - Development</span></a>
                                                    <span style={{color:'#89CFF0'}}>Node.js, C#(.NET Framework)</span>
                                                </li>
                                                <li>
                                                    <a href="/service">DevOps<span> - Development</span></a>
                                                    <span style={{color:'#89CFF0'}}>Terraform, Terragrunt</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    {/* <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>...............................................
                                                   <a href="/service">Awwwards.com <span>- Winner</span></a> 2019 - 2020
                                               </li>
                                               <li>
                                                   <a href="/service">CSS Design Awards <span>- Winner</span></a> 2017 - 2018
                                               </li>
                                                <li>
                                                   <a href="/service">Design nominees <span>- site of the day</span></a> 2013- 2014
                                               </li> 
                                           </ul>
                                       </div>
                                    </TabPanel> */}



                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="#"><span style={{color:'#89CFF0'}}>Intermediate Full Stack Developer</span><span> - Loncel Technologies Ltd - Auckland, NZ</span></a> Nov 2022 - Present
                                               </li>
                                               <li>
                                                   <a href="#"><span style={{color:'#89CFF0'}}>Software Engineer (Full-Stack)</span><span> - MillenniumIT ESP - Colombo, SL</span></a> May 2020 - Nov 2022
                                               </li>
                                               <li>
                                                   <a href="#"><span style={{color:'#89CFF0'}}>Software Engineer Intern</span><span> - MillenniumIT ESP - Colombo, SL </span></a> Aug 2018 - Feb 2019
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="#"><span style={{color:'#89CFF0'}}>BSc. Honours in Information Technology</span><span> - University of Moratuwa, Sri Lanka</span></a> May 2020
                                               </li>
                                               <li>
                                                   <a href="#"><span style={{color:'#89CFF0'}}>Meta Front-End Developer Professional Certificate</span><span> - Coursera, Meta</span></a> Ongoing
                                               </li>
                                               <li>
                                                   <a href="#"><span style={{color:'#89CFF0'}}>Certificate in Web Designing and Development</span><span> - University of Kelaniya, Sri Lanka</span></a> May 2014
                                               </li>
                                               {/* <li>
                                                   <a href="/service">Graphic Designer<span> - ABC Institute, Los Angeles, CA</span></a> 2007
                                               </li> */}
                                           </ul>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;