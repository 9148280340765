import React from 'react'
import {
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon,
    RedditShareButton,
    RedditIcon
} from "react-share";

const SocialShare = ({ url, quote, title, category, description, hashtags }) => {
    return (
        <div className='container'>
            <div className='row'>
                <div className='col' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', paddingTop: '10px'}}>
                    <FacebookShareButton
                        className="social-btn"
                        url={window.location.href}
                        quote={title}
                        hashtag={category}
                        style={{paddingLeft: '10px'}}
                    >
                        <FacebookIcon size={32} round={false} />
                    </FacebookShareButton>
                    <TwitterShareButton
                        className="social-btn"
                        url={window.location.href}
                        title={title}
                        related={["https://twitter.com/dushandev"]}
                        style={{paddingLeft: '10px'}}
                    >
                        <TwitterIcon size={32} round={false} />
                    </TwitterShareButton>
                    <LinkedinShareButton
                        className="social-btn"
                        source={"dushan-ranasinghage"}
                        url={window.location.href}
                        title={title}
                        summary={description}
                        style={{paddingLeft: '10px'}}
                    >
                        <LinkedinIcon size={32} round={false} />
                    </LinkedinShareButton>
                    <RedditShareButton
                        className="social-btn"
                        url={window.location.href}
                        title={title}
                        style={{paddingLeft: '10px'}}
                    >
                        <RedditIcon size={32} round={false} />
                    </RedditShareButton>
                </div>
            </div>
        </div>
    )
}

export default SocialShare